import { CatalogedProduct } from "@features/catalog/domain";
import { getSizedImage, ImageSize } from "@features/shared/data";
import { getColorByHexCode } from "@taager-experience-shared/variants";
import { CatalogedProductModel } from "../models/cataloged-product.model";
import { isSaleActive } from "../utils";

export function mapCatalogedProduct(
  product: CatalogedProductModel,
): CatalogedProduct {
  const colorHexCode = product.attributes.find(
    attribute => attribute.type === "color",
  )?.value;

  return {
    id: product._id,
    sku: product.prodID,
    oldPrice:
      product.price !== product.productPrice ? product.price : undefined,
    customPrice: product.customPrice,
    price: product.productPrice,
    name: product.productName,
    profit: product.productProfit,
    availability: product.productAvailability,
    isAvailableToSeller: product.isAvailableToSeller,
    pricingRange: product.pricingRange,
    sale:
      product.sale && isSaleActive(product.sale)
        ? {
            price: product.sale.productPrice,
            profit: product.sale.productProfit,
          }
        : undefined,
    color: colorHexCode
      ? {
          name: getColorByHexCode(colorHexCode),
          hex: colorHexCode,
        }
      : undefined,
    size: product.attributes.find(attribute => attribute.type === "size")
      ?.value,
    discountedAsSecondInPlacement: product.discountedAsSecondInPlacement,
    images: {
      small: getSizedImage(product.productPicture, ImageSize.small),
    },
    maxPrice: product.productMaxPrice,
    category: {
      id: product.categoryId,
      name: product.Category,
    },
  };
}
