import { BaseRepository } from "@features/shared/data";
import { ReferralInfo, ReferralProgramRepositoryContract } from "../../domain";
import { trackReferralProgramPageLoaded } from "../activities";
import { REFERRAL_PROGRAM_API } from "../constants";

export class ReferralProgramRepository
  extends BaseRepository
  implements ReferralProgramRepositoryContract
{
  public async getReferralInfo(): Promise<ReferralInfo> {
    const response = await this.http.get<ReferralInfo>(
      REFERRAL_PROGRAM_API.GET_REFERRAL_INFO,
    );

    trackReferralProgramPageLoaded();

    return response.data;
  }
}
