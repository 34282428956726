import { clearCache } from "@features/drivers/data";
import { URLS } from "@features/shared/presentation";
import { useOnce } from "@taager/react-hooks";
import { hardNavigate } from "app/utils";

export function ClearCachePage() {
  useOnce(() => {
    clearCache().then(() => {
      hardNavigate(URLS.home);
    });
  });

  return null;
}
