import { environment } from "environment";

export const CATEGORY_PRODUCTS_URL = "/products/category";
export const PRODUCT_DETAILS = "/product-details";

export const CATALOG_API = {
  GET_CATALOGED_PRODUCTS_URL: (isLocked?: boolean) =>
    `/product/favourite?isLocked=${isLocked}`,

  REMOVE_CATALOGED_PRODUCT_URL: (productId: string) =>
    `/product/favourite/${productId}/unset`,

  UPDATE_CUSTOM_PRICE_URL: (productId: string) =>
    `/product/favourite/${productId}`,

  GET_PRODUCT_PRICING_DETAILS_URL: `${environment.BACKEND_URL}admin/orders/product-prices`,

  GET_PRODUCTS_FOR_CATEGORY: `/variant-groups/search`,

  GET_PRODUCT_LOCK_REQUESTS: `/locking-requests`,
};
