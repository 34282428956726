import {
  GetProductsByCategoryPayload,
  LockedProductsRepositoryContract,
  ProductLockRequestStatus,
} from "@features/catalog/domain";
import { BaseRepository } from "@features/shared/data";
import {
  CATALOG_API,
  LOCKED_PRODUCTS_DEFAULT_PAGE_SIZE,
  LOCKED_PRODUCTS_DEFAULT_SORT,
} from "../constants";
import {
  mapLockedProduct,
  mapProductLockRequest,
} from "../mappers/locked-products.mapper";
import { CategorySearchResultProductModel } from "../models/category-search-result.model";
import { ProductLockRequestModel } from "../models/product-lock-request.model";

export class LockedProductsRepository
  extends BaseRepository
  implements LockedProductsRepositoryContract
{
  public async list() {
    const response = await this.http.post<
      GetProductsByCategoryPayload,
      {
        results: CategorySearchResultProductModel[];
      }
    >(CATALOG_API.GET_PRODUCTS_FOR_CATEGORY, {
      pageSize: LOCKED_PRODUCTS_DEFAULT_PAGE_SIZE,
      sortBy: LOCKED_PRODUCTS_DEFAULT_SORT,
      page: 1,
      merchantLockedOnly: true,
    });

    return response.data.results.map(mapLockedProduct);
  }

  public async listProductLockRequests() {
    const params = {
      page: 1,
      pageSize: LOCKED_PRODUCTS_DEFAULT_PAGE_SIZE,
    };

    const response = await this.http.get<{
      results: ProductLockRequestModel[];
    }>(CATALOG_API.GET_PRODUCT_LOCK_REQUESTS, {
      params,
    });

    return response.data.results
      .map(mapProductLockRequest)
      .filter(request => request.status !== ProductLockRequestStatus.ACCEPTED);
  }
}
