export const REFERRAL_PROGRAM_API = {
  GET_REFERRAL_INFO: "/referral",
};

export const REFERRAL_PROGRAM_REWARDS_INFO = {
  firstReward: {
    requiredOrders: 10,
    reward: 100,
  },
  secondReward: {
    requiredOrders: 5,
    reward: 50,
  },
};
