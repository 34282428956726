import cache from "@taager/cache";
import {
  getCurrentLocaleCode,
  setCurrentLocaleCode,
} from "@taager/localization";
import { LOCALE_CODE_CACHE_KEY } from "../constants";
export * from "./clear-cache";

export function updateLocaleCode(localeCode: string): void {
  cache.set(LOCALE_CODE_CACHE_KEY, localeCode);

  setCurrentLocaleCode(localeCode);
}

export function getLocalizedValue(
  value: any,
  defaultLocale?: "en" | "ar",
): string {
  const locales = {
    en: value?.en || value?.englishName,
    ar: value?.ar || value?.arabicName,
  };

  return (
    locales[getCurrentLocaleCode()] ||
    (defaultLocale && locales[defaultLocale]) ||
    value ||
    ""
  );
}

type LocaleCodes = {
  [code: string]: {
    direction: "ltr" | "rtl";
    name: string;
  };
};

export const localeCodesList: LocaleCodes = {
  en: {
    direction: "ltr",
    name: "English",
  },
  ar: {
    direction: "rtl",
    name: "العربية",
  },
  eg: {
    direction: "rtl",
    name: "العربية",
  },
  sa: {
    direction: "rtl",
    name: "العربية",
  },
  ae: {
    direction: "rtl",
    name: "العربية",
  },
  kw: {
    direction: "rtl",
    name: "العربية",
  },
  om: {
    direction: "rtl",
    name: "العربية",
  },
  iq: {
    direction: "rtl",
    name: "العربية",
  },
  tt: {
    direction: "ltr",
    name: "English",
  },
};
