export enum PaymentTypes {
  MOBILE_WALLET,
  BANK_TRANSFER,
  DIGITAL_PAYMENT,
}

export enum WithdrawalPaymentMethod {
  VodafoneCash = "vodafone_cash",
  OrangeCash = "orange_cash",
  EtisalatCash = "etisalat_cash",
  WePay = "we_pay",
  EGPBankTransfer = "egp_bank_transfer",
  INTLBankTransfer = "international_bank_transfer",
  PayoneerTransfer = "payoneer",
  LoyaltyProgramReward = "LoyaltyProgramReward",
  Compensation = "Compensation",
  PreOrderRefund = "PreOrderRefund",
  PreOrderWithdrawal = "PreOrderWithdrawal",
}

export const WithdrawalsPaymentTypes = {
  [PaymentTypes.MOBILE_WALLET]: [
    WithdrawalPaymentMethod.VodafoneCash,
    WithdrawalPaymentMethod.OrangeCash,
    WithdrawalPaymentMethod.EtisalatCash,
    WithdrawalPaymentMethod.WePay,
  ],
  [PaymentTypes.BANK_TRANSFER]: [
    WithdrawalPaymentMethod.EGPBankTransfer,
    WithdrawalPaymentMethod.INTLBankTransfer,
  ],
  [PaymentTypes.DIGITAL_PAYMENT]: [WithdrawalPaymentMethod.PayoneerTransfer],
};

export const egyptWithdrawalPaymentMethods =
  WithdrawalsPaymentTypes[PaymentTypes.MOBILE_WALLET];

export const countriesWithdrawalPaymentMethods = [
  ...egyptWithdrawalPaymentMethods,
  ...WithdrawalsPaymentTypes[PaymentTypes.BANK_TRANSFER],
  ...WithdrawalsPaymentTypes[PaymentTypes.DIGITAL_PAYMENT],
];
