import { resolveUTMDataRepository } from "@di/app";
import { gTagEvent, mixPanelEvent } from "@features/activities/data";
import { CatalogedProduct } from "@features/catalog/domain";
import { CreateOrderPayload } from "@features/checkout/domain";
import { MerchantOrderPreferencesEntity } from "@features/orders/domain";
import { getCurrentLocaleCode } from "@taager/localization";
import user from "user";

export enum CheckoutEvents {
  PLACE_ORDER_INITIATE = "Order_Summary_page_place_order_initiate",
  PLACE_ORDER_RESPONSE = "Order_summary_page_place_order_response",
  CHECKOUT_PREFERENCES_EDIT = "order_preference_edit_button_clicked",
  CHECKOUT_PREFERENCES_CANCEL = "order_preference_cancel_clicked",
  CHECKOUT_PREFERENCES_SAVE = "order_preference_save_changes_clicked",
  ORDER_COMPLETION = "Order_Completion",
}

type OrderCompletionPayload = {
  products: CatalogedProduct[];
  productQuantities: number[];
  paymentAmount: number;
  errors?: Array<{ field: string; status: string }>;
};

export function trackPlaceOrderInitiate() {
  mixPanelEvent(CheckoutEvents.PLACE_ORDER_INITIATE);
}

export function trackPlaceOrderResponse(
  payload: CreateOrderPayload,
  products: CatalogedProduct[],
  error?: { message: string; object: any },
) {
  gTagEvent("order", {
    event_category: "ecommerce",
    event_label: `phoneNum:${user.phoneNumber}, TagerID:${user.id}`,
  });

  const mixpanelPayload = {
    Language: getCurrentLocaleCode(),
    "Pieces Count": payload.productQuantities.reduce(
      (acc, curr) => acc + curr,
      0,
    ),
    "Is Has Variants":
      products.filter(product => product.color || product.size).length > 0,
    Products: products.map(product => ({
      "Product id": product.id,
      "Product name": product.name,
      "Product category": product.category.name,
    })),
    "Total Order Price": payload.cashOnDelivery,
    "Total Profit Amount": payload.orderProfit,
    "Client Full Name": payload.receiverName,
    "Client Mobile Number": payload.phoneNumber,
    "Client Alternative Number": payload.phoneNumber2,
    "Client Governate": payload.province,
    "Client Address": payload.streetName,
    "Merchant Facebook Page": payload.orderSource.pageName,
    "Merchant Page Link": payload.orderSource.pageUrl,
    "Merchant Notes": payload.notes,
    "Order Request Status": error ? "Failed" : "Succeeded",
    errorMessage: error?.message,
    errorObject: error?.object,
  };

  mixPanelEvent(CheckoutEvents.PLACE_ORDER_RESPONSE, mixpanelPayload);
}

export function trackCheckoutPreferencesEdit() {
  mixPanelEvent(CheckoutEvents.CHECKOUT_PREFERENCES_EDIT);
}

export function trackCheckoutPreferencesCancel() {
  mixPanelEvent(CheckoutEvents.CHECKOUT_PREFERENCES_CANCEL);
}

export function trackCheckoutPreferencesSave(
  payload: MerchantOrderPreferencesEntity,
) {
  mixPanelEvent(CheckoutEvents.CHECKOUT_PREFERENCES_SAVE, payload);
}

export function trackOrderCompletion(payload: OrderCompletionPayload) {
  const mixpanelPayload = {
    "Number of Products": payload.products.length,
    "Total Products": payload.productQuantities.reduce(
      (acc, curr) => acc + curr,
      0,
    ),
    "Payment Amount": payload.paymentAmount,
    Status: payload.errors?.length ? "Unsuccessful" : "Success",
    Errors: payload.errors,
    utmData: resolveUTMDataRepository().getData(),
  };

  mixPanelEvent(CheckoutEvents.ORDER_COMPLETION, mixpanelPayload);
}
