import { country } from "@features/country/data";
import {
  mapOrdersList,
  OrderActivityResponseModel,
  OrderActivityWithStatusModel,
  OrderListParamModel,
  OrderModel,
  OrdersListModel,
} from "@features/orders/data";
import {
  CancelOrderData,
  ChildOrdersFilterEntity,
  OrderActivityWithStatusEntity,
  OrderActivityWithStatusResponseEntity,
  OrderEntity,
  OrdersListEntity,
  OrdersRepositoryContract,
  OrderStatusListEntity,
  RateOrderEntity,
} from "@features/orders/domain";
import { BaseRepository } from "@features/shared/data";
import { userApiUrl } from "@features/user/data";
import { UpdateProfileData, User } from "@features/user/domain";
import { ORDER_APIS_ENDPOINTS } from "../constants";

export class OrdersRepository
  extends BaseRepository
  implements OrdersRepositoryContract
{
  // Get Orders List
  public async list(filters: any): Promise<OrdersListEntity> {
    const filterParams = {
      sortBy: "creationDate",
      sortOrder: "descending",
      page: 1,
      pageSize: 10,
      ...filters,
    };

    const { data } = await this.http.get<OrdersListModel>(
      ORDER_APIS_ENDPOINTS.GET_ORDERS_LIST_URL,
      {
        params: filterParams,
      },
    );

    return mapOrdersList(data, filterParams.pageSize, "normal");
  }

  // List Child Orders
  public async getChildOrders(
    childOrdersFilter?: ChildOrdersFilterEntity,
  ): Promise<OrdersListEntity> {
    const params = {
      ...childOrdersFilter,
    };

    const { data } = await this.http.get<any>(
      ORDER_APIS_ENDPOINTS.GET_CHILD_ORDERS_URL,
      {
        params: params as any,
      },
    );

    return mapOrdersList(
      {
        count: data.data.length,
        orders: data.data,
      },
      params.pageSize!,
      "returned",
    );
  }

  // List Failed Orders
  public async getFailedOrders(
    page = 1,
    pageSize = 10,
  ): Promise<OrdersListEntity> {
    const { data } = await this.http.post<OrderListParamModel, { data: any }>(
      ORDER_APIS_ENDPOINTS.GET_FAILED_ORDERS_URL,
      {
        page,
        pageSize,
        country: country.code,
      },
    );

    return mapOrdersList(
      {
        count: data.data.length,
        orders: data.data.failedOrders,
      },
      pageSize,
      "failed",
    );
  }

  // Get Order Activity With Status
  public async getOrderActivityWithStatus(
    filterEntity: OrderActivityWithStatusEntity,
  ): Promise<OrderActivityWithStatusResponseEntity> {
    filterEntity = {
      ...filterEntity,
      pageNum: filterEntity.pageNum || 1,
      pageSize: filterEntity.pageSize || 10,
    };

    const response = await this.http.post<OrderActivityWithStatusEntity, any>(
      ORDER_APIS_ENDPOINTS.GET_ORDER_ACTIVITY_WITH_STATUS_URL,
      filterEntity,
    );

    return response.data;
  }

  // Cancel Order
  public async cancelOrder(data: CancelOrderData): Promise<void> {
    await this.http.patch(ORDER_APIS_ENDPOINTS.CANCEL_ORDER_URL, {
      orders: [
        {
          orderID: data.id,
        },
      ],
      notes: data.notes,
      status: data.status,
    });
  }

  // Get Order Status List
  public async getOrderStatusList(): Promise<OrderStatusListEntity> {
    const { data } = await this.http.get<OrderStatusListEntity>(
      ORDER_APIS_ENDPOINTS.GET_ORDER_STATUS_LIST_URL,
    );

    return data;
  }

  // Rate Order
  public async rateOrder(rateOrder: RateOrderEntity): Promise<void> {
    return await this.http.patch<RateOrderEntity, void>(
      ORDER_APIS_ENDPOINTS.RATE_ORDER_URL,
      rateOrder,
    );
  }

  // Track Order Activity
  public async trackOrderActivity(
    filter?: OrderActivityWithStatusModel,
  ): Promise<OrderActivityResponseModel> {
    const { data } = await this.http.get<OrderActivityResponseModel>(
      ORDER_APIS_ENDPOINTS.TRACK_ORDER_ACTIVITY_URL(
        filter?.filterObj?.orderID ?? "",
      ),
    );

    return data;
  }

  /**
   * Extract Excel Sheet for User Orders
   */
  public async getUserOrdersExtract(filter: any): Promise<OrderModel[]> {
    const bodyData = { data: filter };

    const { data } = await this.http.post<{ data: OrderModel[] }, any>(
      ORDER_APIS_ENDPOINTS.GET_USER_ORDERS_EXTRACT_URL,
      bodyData,
    );

    return data;
  }

  /**
   * Get Products By Product IDs
   **/
  public async getProductsByProdIds(
    prodIDs: string[],
    type: OrderEntity["type"],
  ): Promise<any> {
    const urlsMap = {
      normal: ORDER_APIS_ENDPOINTS.GET_NORMAL_ORDERS_PRODUCTS_URL,
      returned: ORDER_APIS_ENDPOINTS.GET_RETURNED_ORDERS_PRODUCTS_URL,
      failed: ORDER_APIS_ENDPOINTS.GET_NORMAL_ORDERS_PRODUCTS_URL,
    };

    const url = urlsMap[type];

    const { data } = await this.http.post<any, any>(url, prodIDs);

    return data.data;
  }

  // Update User Preferences
  public async updateUserPreferences(data: UpdateProfileData): Promise<User> {
    const response = await this.http.patch<UpdateProfileData, User>(
      userApiUrl.updateProfile,
      data,
    );

    return response;
  }

  public async addOrderReplacement(orderIssues: any): Promise<any> {
    const response = await this.http.post<any, any>(
      ORDER_APIS_ENDPOINTS.ADD_ORDER_REPLACEMENT_URL,
      orderIssues,
    );

    return response;
  }

  public async addOrderCompletion(orderIssues: any): Promise<any> {
    const response = await this.http.post<any, any>(
      ORDER_APIS_ENDPOINTS.ADD_ORDER_COMPLETION_URL,
      orderIssues,
    );

    return response;
  }

  public async addOrderRefund(orderIssues: any): Promise<any> {
    const response = await this.http.post<any, any>(
      ORDER_APIS_ENDPOINTS.ADD_ORDER_REFUND_URL,
      orderIssues,
    );

    return response;
  }
}
