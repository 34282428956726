import { sessionDriver } from "@features/drivers/data";
import cache from "@taager/cache";

export function clearCache(): Promise<void> {
  // Clear local storage cache

  cache.clear();
  // Clear session storage cache
  sessionDriver.clear();
  // Clear all IndexedDB databases
  return new Promise((resolve, reject) => {
    const databases = indexedDB.databases();

    const totals = {
      cleared: 0,
      total: 0,
    };

    databases
      .then(dbs => {
        totals.total = dbs.length;

        setTimeout(() => {
          resolve();
        }, 300);

        if (totals.total === 0) {
          return;
        }

        dbs.forEach(db => {
          if (db.name) {
            const request = indexedDB.deleteDatabase(db.name);

            request.onerror = () => {
              console.error(`Error clearing IndexedDB database: ${db.name}`);
              reject(
                new Error(`Error clearing IndexedDB database: ${db.name}`),
              );
            };

            request.onsuccess = () => {
              totals.cleared++;

              if (totals.cleared === totals.total) {
                resolve();
              }
            };
          }
        });
      })
      .catch(reject);
  });
}
