import {
  ProductCategory,
  ProductSale,
} from "../catalog/cataloged-products.types";

export type LockedProductEntity = {
  id: string;
  sku: string;
  name: string;
  price: number;
  profit: number;
  sale?: ProductSale;
  totalLockedStock: number;
  remainingStock: number;
  lockingExpiresAt: string;
  isLocked: boolean;
  images: {
    small: string;
  };
  category: ProductCategory;
};

export interface ProductLockRequestEntity {
  id: number;
  status: ProductLockRequestStatus;
  sku: string;
  name: {
    en: string;
    ar: string;
  };
  images: {
    small: string;
  };
  requestedQuantity: number;
  acceptedQuantity: number;
  expectedDailyOrders: number;
  rejectionReason?: ProductLockRequestRejectionReason;
  createdAt: string;
}

export enum ProductLockRequestStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}

export enum ProductLockRequestRejectionReason {
  NO_STOCK = "NO_STOCK",
  OUT_OF_STOCK = "OUT_OF_STOCK",
  UNMATCHED_DEMAND = "UNMATCHED_DEMAND",
  BAD_DELIVERY_RATE = "BAD_DELIVERY_RATE",
  BAD_FULFILMENT_RATE = "BAD_FULFILMENT_RATE",
  ALREADY_LOCKED = "ALREADY_LOCKED",
}
