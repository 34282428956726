import {
  LockedProductEntity,
  ProductLockRequestEntity,
} from "@features/catalog/domain";
import { getSizedImage, ImageSize } from "@features/shared/data";
import { formatDate } from "date-fns";
import { CategorySearchResultProductModel } from "../models/category-search-result.model";
import { ProductLockRequestModel } from "../models/product-lock-request.model";

export function mapLockedProduct(
  product: CategorySearchResultProductModel,
): LockedProductEntity {
  return {
    id: product._id,
    sku: product.primaryVariant.prodID,
    name: product.primaryVariant.productName,
    price: product.primaryVariant.productPrice,
    profit: product.primaryVariant.productProfit,
    category: {
      id: product.primaryVariant.categoryId,
      name: product.primaryVariant.categoryName,
    },
    images: {
      small: getSizedImage(
        product.primaryVariant.productPicture,
        ImageSize.small,
      ),
    },
    totalLockedStock:
      product.primaryVariant.availabilityInfo.stockInfo.totalLockedStock,
    remainingStock:
      product.primaryVariant.availabilityInfo.stockInfo.remainingStock,
    lockingExpiresAt:
      product.primaryVariant.availabilityInfo.stockInfo.expiresAt,
    isLocked:
      new Date(product.primaryVariant.availabilityInfo.stockInfo.expiresAt) >
      new Date(),
    sale: product.primaryVariant.sale
      ? {
          price: product.primaryVariant.sale.productPrice,
          profit: product.primaryVariant.sale.productProfit,
        }
      : undefined,
  };
}

export function mapProductLockRequest(
  productLockRequest: ProductLockRequestModel,
): ProductLockRequestEntity {
  return {
    id: productLockRequest.id,
    status: productLockRequest.status,
    sku: productLockRequest.sku,
    name: productLockRequest.productName,
    images: {
      small: getSizedImage(productLockRequest.productImage, ImageSize.small),
    },
    requestedQuantity: productLockRequest.requestedQuantity,
    acceptedQuantity: productLockRequest.acceptedQuantity,
    expectedDailyOrders: productLockRequest.expectedDailyOrders,
    createdAt: formatDate(productLockRequest.createdAt, "dd-MM-yyyy"),
    rejectionReason: productLockRequest.rejectionReason,
  };
}
