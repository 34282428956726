import { mixPanelEvent } from "@features/activities/data";

export enum ReferralProgramEvent {
  REFERRAL_PROGRAM_PAGE_LOADED = "referral_program_page_loaded",
  REFERRAL_PROGRAM_WHATSAPP_CLICKED = "referral_program_whatsapp_clicked",
  REFERRAL_PROGRAM_COPY_LINK_CLICKED = "referral_program_copy_link_clicked",
  REFERRAL_PROGRAM_COPY_CODE_CLICKED = "referral_program_copy_code_clicked",
}

export function trackReferralProgramPageLoaded() {
  mixPanelEvent(ReferralProgramEvent.REFERRAL_PROGRAM_PAGE_LOADED);
}

export function trackReferralProgramWhatsappClicked() {
  mixPanelEvent(ReferralProgramEvent.REFERRAL_PROGRAM_WHATSAPP_CLICKED);
}

export function trackReferralProgramCopyLinkClicked() {
  mixPanelEvent(ReferralProgramEvent.REFERRAL_PROGRAM_COPY_LINK_CLICKED);
}

export function trackReferralProgramCopyCodeClicked() {
  mixPanelEvent(ReferralProgramEvent.REFERRAL_PROGRAM_COPY_CODE_CLICKED);
}
